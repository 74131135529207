import React from 'react';
import { Box, Typography } from '@mui/material';

const WorkFeedbackItem = ({ question }) => {
  const colorMap = {
    1: "#edd0ca",
    2: "#ebe0d1",
    3: "#e6e6d1",
    4: "#dff0e3",
  }

  const color = colorMap[question.score];
  return (
    <Box sx={{ p: 1, backgroundColor: color }}>
      <Typography variant="body1">
        <strong>{question.question}</strong>
      </Typography>
      <Typography variant="body2">
        {question.answer}
      </Typography>
    </Box>
  );
};

export default WorkFeedbackItem;
