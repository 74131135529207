import React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import WorkFeedback from './WorkFeedback';

const WorkEvaluation = ({ data }) => {
  return (
    <Box sx={{ textAlign: 'center', mt: 2 }}>
      {data.status !== 'complete' && data.status !== 'error' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress />
          <Typography variant="body2" sx={{ mt: 1 }}>
            Evaluating
          </Typography>
        </Box>
      )}

      {data.status === 'error' && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Something has gone wrong. Please try again.
          </Typography>
        </Box>
      )}
      <Box>
        {data?.classification?.summary && (
          <Typography variant="body2">
            {data.classification.summary}
            {!data.classification.valid && ' I am only programmed to evaluate works of business writing (memos, plans, reports, etc.)!'}
          </Typography>
        )}
        
        {data?.evaluation && data.classification.valid && (
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              textAlign: 'left',
              whiteSpace: 'pre-wrap',
            }}
          >
            <WorkFeedback evaluation={data.evaluation} />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default WorkEvaluation;
