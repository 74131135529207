import React, { useState, react } from 'react';
import { Button, TextField, Typography, Box } from '@mui/material';
import { db, serverTimestamp } from '../services/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const SubmitWork = () => {
  const [submissionText, setSubmissionText] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmission = async () => {
    if (!submissionText) {
      setError('Submission text is required.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'works'), {
        submissionText,
        createdAt: serverTimestamp(),
        status: 'Submitted',
      });
      console.log('Document written with ID: ', docRef.id);
      setSuccessMessage('Work submitted successfully!');
      setSubmissionText('');
      setError('');

      // Redirect to the new document page
      navigate(`/w/${docRef.id}`);
    } catch (error) {
      console.error('Error during submission:', error);
      setError('Failed to submit the work. Please try again.');
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h6">
        Submit a work of business writing to receive feedback.
      </Typography>
      <TextField
        label="Work Submission"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        value={submissionText}
        onChange={(e) => setSubmissionText(e.target.value)}
        error={!!error}
        helperText={error}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary" onClick={handleSubmission}>
        Submit
      </Button>
      {successMessage && <Typography color="green" sx={{ mt: 2 }}>{successMessage}</Typography>}
    </Box>
  );
};

export default SubmitWork;
