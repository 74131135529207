import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import SubmitWork from './Components/SubmitWork';
import WorkDetail from './Components/WorkDetail';

function App() {
  return (
    <Router>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box component="main" sx={{ textAlign: 'center', mt: 3 }}>
          <Typography variant="h4" gutterBottom>
            Examind Business Writing Feedback Machine
          </Typography>
          <Typography variant="h5" gutterBottom>
            Rubric Customized for Tippie College of Business
          </Typography>
          <Box sx={{ mt: 5 }}>
            <Routes>
              <Route path="/" element={<SubmitWork />} />
              <Route path="/w/:docId" element={<WorkDetail />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
