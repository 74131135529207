import React from 'react';
import { Box, Typography } from '@mui/material';
import WorkFeedbackSection from './WorkFeedbackSection';

const WorkFeedback = ({ evaluation }) => {
  return (
    <Box sx={{ mt: 2 }}>
      {evaluation.sections.map((section, index) => (
        <WorkFeedbackSection key={index} section={section} />
      ))}
    </Box>
  );
};

export default WorkFeedback;
