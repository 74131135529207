import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Box, Button, ButtonGroup } from '@mui/material';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../services/firebase';
import WorkEvaluation from './WorkEvaluation';

const WorkDetail = () => {
  const { docId } = useParams();
  const navigate = useNavigate();
  const [workData, setWorkData] = useState(null);
  const [showSubmission, setShowSubmission] = useState(false);

  useEffect(() => {
    const docRef = doc(db, 'works', docId);
    const unsubscribe = onSnapshot(docRef, (snapshot) => {
      if (snapshot.exists()) {
        setWorkData(snapshot.data());
      } else {
        setWorkData(null);
      }
    });

    return () => unsubscribe();
  }, [docId]);

  if (!workData) {
    return (
      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Typography variant="h6">Loading work data...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2, maxWidth: 800, margin: 'auto', textAlign: 'center' }}>
      <Button
        variant="outlined"
        sx={{ mb: 2 }}
        onClick={() => navigate('/')}
      >
        Submit Another Work
      </Button>
      
      <Typography variant="h5" gutterBottom>
        Evaluating Work ID: {docId}
      </Typography>
      <ButtonGroup sx={{ mt: 2, mb: 2 }}>
        <Button
          variant={showSubmission ? 'contained' : 'outlined'}
          onClick={() => setShowSubmission(true)}
        >
          Show Submission
        </Button>
        <Button
          variant={!showSubmission ? 'contained' : 'outlined'}
          onClick={() => setShowSubmission(false)}
        >
          Show Feedback
        </Button>
      </ButtonGroup>

      {showSubmission ? (
        <Box sx={{ textAlign: 'left', mt: 2 }}>
          <Typography variant="h6">Submission Text</Typography>
          <Box component="pre" sx={{ fontSize: '1rem', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
            {workData.submissionText}
          </Box>
        </Box>
      ) : (
        <WorkEvaluation data={workData} />
      )}
    </Box>
  );
};

export default WorkDetail;
